import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import FaqAccordion from "../../../components/faq/faq-accordion";

// Helpers
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import getSortedFaqDataByCategory from "../../../helpers/getSortedFaqDataByCategory";

const ZelleFaq = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/mobile-banking-app/zelle/hero-zelle-01032023-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/mobile-banking-app/zelle/hero-zelle-01032023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/mobile-banking-app/zelle/hero-zelle-01032023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/mobile-banking-app/zelle/hero-zelle-01032023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/mobile-banking-app/zelle/hero-zelle-01032023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/mobile-banking-app/zelle/hero-zelle-01032023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/mobile-banking-app/zelle/hero-zelle-01032023-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "zelle-hero",
    ...getHeroImgVariables(imgData),
    altText: "A young woman using WaFd Bank mobile app on her mobile phone.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Send Money in the Moment with Zelle<sup>&reg;</sup>",
            class: "text-white"
          }
        }
      ]
    }
  };

  const { sortedFaqs, categoryFootnotes, schema } = getSortedFaqDataByCategory(["Zelle"]);

  const title = "Zelle FAQs - Top Zelle Questions Answered",
    description =
      "Get the answers you need to your top Zelle questions in our Zelle FAQs page. Learn how you can connect your bank account and send money with just an email address or mobile number.";

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/mobile-banking-app/zelle-faq"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-zelle-01032023-250.jpg"
      }
    ],
    schema
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/mobile-banking-app",
      title: "Mobile-banking-app"
    },
    {
      id: 3,
      title: "Zelle FAQs",
      active: true
    }
  ];

  const faqAccordionData = {
    id: "zelle-faq-section",
    pageTitle: "Zelle<sup>&reg;</sup> Frequently Asked Questions (FAQ)",
    title: null,
    // pass faqData instead of faqCategoryNames
    faqData: {
      sortedFaqs,
      categoryFootnotes
    }
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default ZelleFaq;
